import { react2Angular } from 'FrontRoyalReact2Angular';
import FrontRoyalErrorBoundary from '../FrontRoyalErrorBoundary';

const angularModule = angular.module('FrontRoyal.ErrorBoundary', []);

angularModule.component(
    'frontRoyalErrorBoundaryReactComponent',
    react2Angular(FrontRoyalErrorBoundary, [
        'error',
        'resetErrorBoundary',
        'other',
        'notLoggedIn',
        'notPermitted',
        'notAcceptable',
        'disconnected',
    ]),
);

export default angularModule;
