import { setAuthHeaders } from 'ReduxHelpers';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getCameraSupported, cameraSources, CameraSource, requestPermissions, getPhoto } from 'MobileFileUpload';
import angularModule from '../front_royal_form_module';
import template from '../../views/inputs/upload_avatar.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('uploadAvatar', [
    '$injector',
    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const $window = $injector.get('$window');
        const LinkedinOauthService = $injector.get('LinkedinOauthService');
        const Capabilities = $injector.get('Capabilities');

        return {
            restrict: 'E',
            templateUrl,
            require: '^?form',
            scope: {
                isRequired: '<?',
                user: '<',
                disableLinkedin: '<?',
                subTextKey: '<?',
            },
            link(scope, _elem, _attrs, formController) {
                if (angular.isUndefined(scope.isRequired)) {
                    scope.isRequired = true;
                }

                scope.subTextKey = scope.subTextKey || 'front_royal_form.inputs.upload_avatar.saved_automatically';

                scope.formController = formController;

                const translationHelper = new TranslationHelper('front_royal_form.inputs.upload_avatar');
                scope.uploadString = translationHelper.get('upload');
                scope.syncFromLinkedInString = translationHelper.get('sync_from_linked_in');

                function setNewValueOnModel(url) {
                    scope.user.avatar_url = url;

                    // Since avatar_url is saved both on the currentUser
                    // and on currentUser.career_profile, we need to update
                    // both.  See https://trello.com/c/lJmT3mWR
                    if (scope.user.career_profile) {
                        scope.user.career_profile.avatar_url = url;
                    }
                }

                const setShowAccessTip = value => {
                    scope.showAccessTip = value;
                    scope.$digest();
                };

                const setLoading = value => {
                    scope.loading = value;
                    scope.$digest();
                };

                //---------------------------
                // Mobile upload support
                //---------------------------

                scope.cameraSupported = getCameraSupported();

                if (scope.cameraSupported) {
                    scope.cameraSources = cameraSources.filter(source => source !== CameraSource.Prompt);
                    scope.cameraSourceLabels = {};
                    scope.cameraSourceLabels[CameraSource.Camera] = translationHelper.get('camera_source_camera');
                    scope.cameraSourceLabels[CameraSource.Photos] = translationHelper.get('camera_source_photos');
                }

                const uploadPhoto = async dataUrl =>
                    fetch(scope.user.avatarUploadUrl(), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            ...setAuthHeaders(new Headers()),
                        },
                        body: JSON.stringify({ avatar_image: dataUrl }),
                    });

                scope.getAndUploadPhoto = async source => {
                    const permissionStatus = await requestPermissions(source);

                    if (permissionStatus === 'denied') {
                        // If the user just denied or previously denied access to the camera or photo library, show some UI
                        // indicating as much and stop here.
                        setShowAccessTip(true);
                        return;
                    }
                    // unset showAccessTip here in case the user went out to settings, came back, and tapped the button again
                    setShowAccessTip(false);

                    setLoading(true);
                    try {
                        const { dataUrl } = await getPhoto(source);
                        const response = await uploadPhoto(dataUrl);
                        const data = await response.json();
                        if (data?.contents?.avatar?.url) setNewValueOnModel(data.contents.avatar.url);
                    } catch {
                        // Noop - the most likely scenario in which either of the above will throw an exception
                        // is when the user cancels either taking or selecting a photo. In which case there's
                        // nothing actionable, we should just reset the state.
                        // There is a chance that we swallow an error from uploadPhoto, but we should have an associated
                        // server-side error and this interaction isn't one where we'd want to show the user an
                        // API error on a failed upload.
                    } finally {
                        setLoading(false);
                    }
                };

                //---------------------------
                // Web upload support
                //---------------------------

                scope.onFileSelect = async ($file, errFiles) => {
                    // onFileSelect will fire immediately on followup clicks to the avatarUpload button, i.e. before a file
                    // is selected. It will fire again when a file is selected, so we just dump out early here.
                    if (!$file) return;

                    // Note that we just set scope.loading directly here, without a digest, instead of calling setLoading(...).
                    // That's because ng-file-upload has internal digests for click handlers, etc.
                    scope.loading = true;
                    try {
                        const response = await frontRoyalUpload.handleNgfSelect($file, errFiles, file => ({
                            url: scope.user.avatarUploadUrl(),
                            data: { avatar_image: file },
                            supportedFormatsForErrorMessage: '.jpg, .png, .svg, .gif',
                        }));
                        if (response.data?.contents?.avatar?.url) setNewValueOnModel(response.data.contents.avatar.url);
                    } catch {
                        // Noop - the most likely scenario in which handleNgfSelect would throw an exception
                        // would be an API error on the upload_avatar_endpoint, but we should have an associated
                        // server-side error and this interaction isn't one where we'd want to show the user an
                        // API error on a failed upload.
                    } finally {
                        scope.loading = false;
                    }
                };

                //---------------------------
                // LinkedIn Integration
                //---------------------------

                // We hide the ability to sync your avatar from LinkedIn if:
                // 1) On Cordova
                // 2) Explicitly asked to with the attribute option
                // 3) We detect that cookie storage isn't working (cookies are required for the
                //    CSRF checking, see linkedin_controller.rb)
                scope.hideSyncFromLinkedIn = $window.CORDOVA || !!scope.disableLinkedin || !Capabilities.cookies;

                scope.syncLinkedInAvatar = $event => {
                    const origValue = scope.user.avatar_url;
                    setNewValueOnModel(null);

                    // dirty the form so that the user can't navigate away without unsaved_changes_confirm
                    // modal firing no matter what happens with the LinkedIn popup. Especially in the case
                    // of closing the popup without logging in to LinkedIn and syncing. See: https://trello.com/c/n57UNi1w
                    if (scope.formController) {
                        scope.formController.$setDirty();
                    }

                    // This prevents the screen from scrolling down when
                    // the button is pressed
                    $event.preventDefault();

                    LinkedinOauthService.getProfile()
                        .then(profile => {
                            const profilePictureUrl = profile.pictureUrl;
                            if (profilePictureUrl) {
                                setNewValueOnModel(profilePictureUrl);
                                scope.user.save();
                                scope.linkedinMessage = undefined;
                            } else {
                                setNewValueOnModel(origValue);
                                scope.linkedinMessage = translationHelper.get('linkedin_no_picture');
                            }
                        })
                        .catch(() => {
                            setNewValueOnModel(origValue);
                            scope.linkedinMessage = translationHelper.get('linkedin_error');
                        });
                };
            },
        };
    },
]);
