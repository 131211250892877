import { isNotAcceptableError } from 'ReduxHelpers';
import { type ErrorHandlingComponent } from '../FrontRoyalErrorBoundary.types';

const ShowValidationErrors: ErrorHandlingComponent = function ShowValidationErrors({ error, resetErrorBoundary }) {
    if (!isNotAcceptableError(error)) throw new Error('Invalid error type');

    // FIXME: split on commas or something. You might be able to follow the example in failed_validation_dir.js
    const message = error.data?.message || '';
    return (
        <>
            {message}{' '}
            <button type="button" onClick={resetErrorBoundary}>
                Dismiss
            </button>
        </>
    );
};

export { ShowValidationErrors };
