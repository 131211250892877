import { memo, useCallback, useEffect, useMemo } from 'react';
import {
    isAbortError,
    isNotAcceptableError,
    isNotLoggedInError,
    isNotPermittedError,
    isRetriableNetworkError,
    isRTKQueryError,
    type RTKQueryError,
} from 'ReduxHelpers';
import { angularInjectorProvider } from 'Injector';
import { type AnyObject } from '@Types';
import { type ErrorLogService as ErrorLogServiceClass } from 'ErrorLogging';
import { type EventLogger as EventLoggerClass } from 'EventLogger';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import {
    type FrontRoyalErrorBoundaryProps,
    type FallbackErrorHandlingComponent,
    type RetryFunction,
} from './FrontRoyalErrorBoundary.types';

/*
    The FrontRoyalErrorBoundaryComponent will be rendered when an error is caught. The parameters it
    takes represent the error, some information about the context in which the error occurred, and some
    configuration about how we want to handle different types of errors:

    - `error` is the error that was caught
    - `retry` is all we need to know about the context of the error. It is a function that can be triggered
            to retry whatever we were doing when the error happened.
    - `resetErrorBoundary` is provided by react-error-boundary. We trigger it once we've resolved the error and
            we want the error UI to go away
    - The rest of the parameters come from the `ErrorHandlingOptions` type, which defines a map of error types to
            components that should be rendered to handle each type of error.
*/
function FrontRoyalErrorBoundaryComponent({
    error,
    resetErrorBoundary,
    other,
    notLoggedIn,
    notPermitted,
    notAcceptable,
    disconnected,
}: FrontRoyalErrorBoundaryProps): JSX.Element {
    const PrimaryErrorHandlingComponent = useMemo(() => {
        if (isRetriableNetworkError(error)) {
            return disconnected;
        }
        if (isNotLoggedInError(error)) {
            return notLoggedIn;
        }
        if (isNotPermittedError(error)) {
            return notPermitted;
        }
        if (isNotAcceptableError(error)) {
            return notAcceptable;
        }
        return null;
    }, [error, disconnected, notLoggedIn, notPermitted, notAcceptable]);

    const ErrorHandlingComponent = useMemo(() => {
        if (PrimaryErrorHandlingComponent) {
            return PrimaryErrorHandlingComponent;
        }

        // If we are falling back to the "other" component, then this is an unexpected error
        // that we have not handled. We should log it.
        logError(error);
        return other;
    }, [PrimaryErrorHandlingComponent, error, other]);

    const BackUpPlanForTheBackUpPlan = useMemo(
        () => getBackupPlanForTheBackupPlan({ error, resetErrorBoundary, Fallback: other }),
        [error, resetErrorBoundary, other],
    );

    const retry: RetryFunction = useCallback(() => {
        // See https://trello.com/c/D7HjOuv1/85-feat-implement-disconnected-error-handling-in-the-subcomponent-case
        throw new Error('Retry not implemented');
    }, []);

    return (
        /*
            It may seem strange that we have an ErrorBoundary component inside of an ErrorBoundary component.
            This is necessary to handle any errors that might happen inside of the other error handlers.

            The handler used as `other` should be very simple. If any errors happen in that one, then you're gonna
            be out of luck.
        */
        <ErrorBoundary FallbackComponent={BackUpPlanForTheBackUpPlan}>
            {ErrorHandlingComponent === other ? (
                <ErrorHandlingComponent error={error} resetErrorBoundary={resetErrorBoundary} />
            ) : (
                <ErrorHandlingComponent error={error} resetErrorBoundary={resetErrorBoundary} retry={retry} />
            )}
        </ErrorBoundary>
    );
}

function handleRTKQueryErrorLogging(error: RTKQueryError) {
    const status = error?.status;
    const eventPayload = {
        label: error.endpointPath,
        server_error: {
            path: error.endpointPath,
            status,
            message: error.data?.message,
        },
        fingerprint: error.endpointPath,
    };

    const ignorableErrors = [
        530, // cloudflare dns issue
    ];

    return {
        eventPayload,
        shouldNotify: !ignorableErrors.includes(status),
        errorForNotify: new Error(`Received status code ${status} in request to ${error.endpointPath}`),
    };
}

const MAX_EVENT_PAYLOAD_MESSAGE_LENGTH = 1024;

function handleErrorInstanceLogging(error: Error) {
    return {
        eventPayload: {
            label: (error.message || '').slice(0, 256),
            message: (error.message || '').slice(0, MAX_EVENT_PAYLOAD_MESSAGE_LENGTH),
        },
    };
}

type ToStringAble = {
    toString(): string;
};

function isToStringAble(value: unknown): value is ToStringAble {
    if (typeof value === 'string') return true;
    if (!value) return false;
    return typeof value === 'object' && 'toString' in value;
}

function logError(error: unknown): void {
    let eventPayload: AnyObject;
    let shouldNotify = true;
    let errorForNotify: Error | string =
        'Unspecified error (We should not be notifying sentry without overriding errorForNotify)';

    if (isAbortError(error)) {
        shouldNotify = false;
        eventPayload = {
            label: error.name,
            message: error.message,
            error: error.message,
        };
    } else if (isRTKQueryError(error)) {
        ({ eventPayload, shouldNotify, errorForNotify } = handleRTKQueryErrorLogging(error));
    } else if (error instanceof Error) {
        errorForNotify = error;
        ({ eventPayload } = handleErrorInstanceLogging(error));
    } else {
        const message = isToStringAble(error) ? error.toString() : 'Unknown error';
        eventPayload = {
            message: message.slice(0, MAX_EVENT_PAYLOAD_MESSAGE_LENGTH),
        };
        errorForNotify = new Error(message);
    }

    if (shouldNotify) {
        const ErrorLogService = angularInjectorProvider.get<typeof ErrorLogServiceClass>('ErrorLogService');
        ErrorLogService.notify(errorForNotify, error !== errorForNotify ? error : null, eventPayload);
    } else {
        const EventLogger = angularInjectorProvider.get<EventLoggerClass>('EventLogger');
        EventLogger.log('error', eventPayload);
    }
}

// This function creates and returns a component that can be passed to ErrorBoundary's FallbackComponent prop.
function getBackupPlanForTheBackupPlan({
    error: originalError,
    resetErrorBoundary: originalResetErrorBoundary,
    Fallback,
}: {
    error: FrontRoyalErrorBoundaryProps['error'];
    resetErrorBoundary: FrontRoyalErrorBoundaryProps['resetErrorBoundary'];
    Fallback: FallbackErrorHandlingComponent;
}) {
    const BackUpPlanForTheBackupPlan: React.FC<FallbackProps> = ({
        error: errorThrownByErrorHandler,
        resetErrorBoundary,
    }) => {
        function resetBothBoundaries() {
            originalResetErrorBoundary();
            resetErrorBoundary();
        }

        useEffect(() => {
            const ErrorLogService = angularInjectorProvider.get<typeof ErrorLogServiceClass>('ErrorLogService');
            ErrorLogService.notify(errorThrownByErrorHandler, originalError);
        }, [errorThrownByErrorHandler]);

        return <Fallback error={originalError} resetErrorBoundary={() => resetBothBoundaries()} />;
    };
    return BackUpPlanForTheBackupPlan;
}

export const FrontRoyalErrorBoundary = memo(
    FrontRoyalErrorBoundaryComponent,
) as typeof FrontRoyalErrorBoundaryComponent;

export default FrontRoyalErrorBoundary;
